import React, { useEffect, useRef } from 'react';
import Layout from '../components/layout';
import WebinarRegistrationPopup from '../components/Popup/webinar-registration-popup';
import SEO from "../components/seo";
import InternationaPatentImg from '../images/internation-patent.webp';
import IPProtectionImg from '../images/aws-openai-soc.jpg';
import '../styles/webinar-page.css';

const WebinarNavigatingInternationalPatent = () => {
  const WEBINAR_TITLE = 'Navigating International Patent Laws As A Startup';
  const seo = {
    metaDesc: WEBINAR_TITLE
  }
  const WEBINAR_AI_MEETS = "https://webinarkit.com/webinar/registration/oneclick/67c81ad74c252cf9088774e4?date=jit_15";
  const childRef = useRef();
  const openPopup = () => {
    //
    childRef.current?.open(WEBINAR_AI_MEETS);
  }
  //
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <Layout activeMenu={'webinar'}>
      <SEO title={`Webinar ${WEBINAR_TITLE} - Inventiv.org`} canonical='/webinar-navigating-international-patent-laws-as-a-startup' seo={seo} />

      <div class="sub-banner" style={{ height: 'auto', backgroundColor: '#0C6873' }}>
        <div class="container py-5">
          <div class="text-center">
            <h1 className='text-white'> International Patent </h1>
            <h6 className='text-white'>The webinar is provided free of cost and is a collaboration between Inventiv and PatentPC</h6>
          </div>
        </div>
      </div>

      {/* <!-- Rsvp start --> */}
      <div class="rsvp content-area-2" id="rsvp">
        <div class="container px-5">
          <div className='px-5'>
            <div class="row">
              <div class="col-12 wow fadeInUp delay-04s">
                <div class="row">
                  <h1 className="text-center bold">{WEBINAR_TITLE}</h1>
                  <h5 className='text-center'>Created By Patent Lawyers, For Patent Lawyers. </h5>
                </div>
              </div>
            </div>
            <div className='my-5'>
              <div className='row'>
                <div className='col-md-12 row-flex wow fadeInUp delay-04s'>
                  <div className='div-content'>
                    <div className='circular_image'>
                      <img style={{ display: 'block', objectFit: 'cover' }} src={InternationaPatentImg} alt={WEBINAR_TITLE}></img>
                    </div>
                    <p className='text-center'>Don’t miss this webinar by our partner PatentPC</p>
                    <h5 className='bold mt-2 text-center'>Instant watch session available. Join now!</h5>

                  </div>
                  <div className='div-content'>
                    <p>Expanding your innovation beyond borders is exciting, but it comes with challenges—one of the biggest being patent protection. Securing a patent in your home country is one thing, but ensuring protection across multiple markets requires a well-planned strategy.</p>

                    <p>Without proper patent coverage, your invention can be copied, sold, or used by competitors in foreign countries. This not only affects revenue but can also impact the long-term value of your business. Understanding international patent laws helps you safeguard your invention, expand your business, and secure competitive advantages in global markets.</p>

                    <p>This webinar will guide you through the process of protecting your intellectual property worldwide, covering key legal frameworks, filing strategies, and common pitfalls to avoid. By the end, you’ll have a clear roadmap for securing patents in multiple jurisdictions and ensuring your innovation stays protected.</p>



                    <p className='text-center'>
                      <a href="#" onClick={e => { e.preventDefault(); openPopup() }} class="btn btn-default my-3" style={{ width: '100%' }}>Watch Webinar Now</a>
                    </p>

                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12 wow fadeInUp delay-04s'>
                  <div className='div-content mw-350'>

                    <h3>How Do International Patent Laws Work?</h3>
                    <p>There is no single global patent that grants protection in every country. Instead, each country has its own patent system. However, there are international agreements and treaties that streamline the process, making it easier for inventors to secure protection in multiple regions.</p>

                    <p>Some of the most important frameworks include:</p>
                    <p className='ps-5'>
                      <ul style={{ listStyle: 'disc' }}>
                        <li><strong>Patent Cooperation Treaty (PCT):</strong> Allows inventors to file a single application that covers multiple countries.</li>
                        <li><strong>European Patent Convention (EPC):</strong> Simplifies the process for obtaining patents across Europe.</li>
                        <li><strong>Madrid Protocol (For Trademarks):</strong> Though focused on trademarks, it provides a model for managing international IP rights efficiently.</li>
                      </ul>
                    </p>

                    <p>Understanding these frameworks helps businesses make informed decisions about where and how to seek patent protection.</p>

                  </div>
                  <div className='div-content mw-350'>
                    <h3>Filing an International Patent: Step-by-Step Guide</h3>
                    <p>Securing international patents is a multi-step process. Here’s how it works:</p>

                    <p><strong>Start with a Strong Domestic Patent:</strong></p>
                    <p>Before filing internationally, make sure your invention is protected in your home country. A strong initial patent application provides a foundation for international filings and ensures priority rights.</p>
                    <p><strong>Choose the Right Filing Route:</strong></p>
                    <p>You have two main options for filing internationally: Direct National Filing and the PCT Route. Each has advantages depending on your strategy.</p>
                    <p className='ps-5'>
                      <ul style={{ listStyle: 'disc' }}>
                        <li><strong>Direct National Filing:</strong> Applying separately in each country where you seek protection.</li>
                        <li><strong>PCT Route:</strong> A single application that extends to multiple countries.</li>
                      </ul>
                    </p>
                    <p><strong>Conduct a Global Patent Search:</strong></p>
                    <p>Before filing, it’s essential to check if similar patents already exist in other countries. This reduces the risk of rejection and helps refine your application.</p>
                    <p><strong>File Within Priority Deadlines:</strong></p>
                    <p>Most international patent laws follow a 12-month priority rule. Missing this window can weaken your protection.</p>
                    <p><strong>Navigate Examination and Approval:</strong></p>
                    <p>Each country has its own rules and examination procedures. Some jurisdictions have stricter requirements, requiring additional modifications or legal assistance.</p>
                  </div>
                  <div className='div-content mw-350'>
                    <h3>Key Challenges in International Patent Filing</h3>
                    <p>International patent protection is valuable, but it comes with hurdles. Understanding these challenges can help businesses prepare and navigate the complexities of the process.</p>
                    <p className='ps-5'>
                      <ul style={{ listStyle: 'disc' }}>
                        <li><strong>High Costs:</strong> Filing in multiple countries can be expensive.</li>
                        <li><strong>Different Patent Standards:</strong> Requirements vary by country.</li>
                        <li><strong>Language Barriers:</strong> Many countries require translations.</li>
                        <li><strong>Enforcement Issues:</strong> Securing a patent is one step; enforcing it is another.</li>
                      </ul></p>

                  </div>
                  <div className='div-content mw-350'>
                    <h3>Strategies for Cost-Effective International Patents</h3>
                    <p>Patenting globally doesn’t have to drain resources. By using strategic approaches, businesses can maximize protection while keeping costs manageable.</p>
                    <ul>
                      <li><strong>Use the PCT System.</strong>
                        <p>The PCT system allows businesses to delay costly national filings while maintaining global patent options, making it an efficient approach for startups and small businesses.</p></li>
                      <li><strong>Focus on Key Markets.</strong>
                        <p>Prioritizing regions where your business operates or plans to expand ensures you allocate resources effectively while maximizing protection.</p></li>
                      <li><strong>Leverage Regional Patents.</strong>
                        <p>Systems like the European Patent Convention allow protection in multiple countries under one application, reducing costs and complexity.</p></li>
                      <li><strong>Seek Funding or Government Support.</strong>
                        <p>Many governments provide grants and incentives to help startups and businesses protect their intellectual property globally.</p></li>
                    </ul>
                  </div>
                  <div className='div-content mw-350'>
                    <h3>The Role of Patent Attorneys in International Filings</h3>
                    <p>International patent law is complex, and mistakes can be costly. Working with experienced patent attorneys helps avoid pitfalls, navigate legal differences, and streamline the process. A good attorney will:</p>
                    <p className='ps-5'>
                      <ul style={{ listStyle: 'disc' }}>
                        <li>Ensure compliance with different country requirements.</li>
                        <li>Draft applications to maximize approval chances.</li>
                        <li>Assist in enforcement if infringement occurs.</li>
                      </ul>
                    </p>
                  </div>
                  <div className='div-content mw-350'>
                    <h3>Final Thoughts: Securing Your Innovation Globally</h3>
                    <p>Expanding into international markets requires more than just a great product—it requires solid legal protection. Patents are valuable business assets that give inventors the power to control their innovations and maximize profitability worldwide.</p>
                    <p>
                    Understanding international patent laws ensures that you make informed decisions, avoid common mistakes, and strengthen your business’s long-term success. By planning ahead and choosing the right strategy, you can navigate global patent systems with confidence and keep your innovations secure.
                    </p>
                    <p>Let’s get started on this journey to mastering international patent protection!</p>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6 offset-md-3'>
                  <p className='text-center'>
                    <a href="#" onClick={e => { e.preventDefault(); openPopup() }} class="btn btn-default my-3" style={{ width: '100%' }}>Watch Webinar Now</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container px-5'>
        <div class="d-flex justify-content-center">
          <img src={IPProtectionImg} alt='Our lawyers have worked on IP protection for' />
        </div>
      </div>
      <WebinarRegistrationPopup ref={childRef}></WebinarRegistrationPopup>
      {/* <!-- Rsvp end --> */}
    </Layout>
  )
};

export default WebinarNavigatingInternationalPatent;